import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import serviceIcon1 from '../../assets/images/services/service-icon1.png'
import serviceIcon2 from '../../assets/images/services/service-icon2.png'
import serviceIcon3 from '../../assets/images/services/service-icon3.png'
import serviceIcon4 from '../../assets/images/services/service-icon4.png'
import serviceIcon5 from '../../assets/images/services/service-icon5.png'
import serviceIcon6 from '../../assets/images/services/service-icon6.png'
import { ImOffice } from 'react-icons/Im';
import { AiOutlineDashboard } from 'react-icons/Ai';
import { FaGlasses} from 'react-icons/Fa';




const OurFeatures = () => {
    return (
        <section className="services-area pt-100 pb-70 bg-f1f8fb">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="feature" /> 
                        JumboFun's Features
                    </span>

                    <h2>致力用數據改變世界!</h2>
                    <p>麵粉碰到師傅後，成為令人回味無窮的糕點</p>
                    <p>數據碰到JumboFun後，成為令人豁然開朗的關鍵點</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                   <ImOffice style={{'font-size': 70}}/>
                            </div>
                            <h3>豐富的商業經驗</h3>
                            <p style={{'height': 120}}>我們團隊不同於程式導向的軟體公司，我們有豐富的業界流程和策略設計經驗，軟體只是我們對問題精準分析後，所提供的Solution</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <AiOutlineDashboard style={{'font-size': 70}}/>
                            </div>
                            <h3>簡單清楚的儀表板</h3>
                            <p style={{'height': 120}}>與JumboFun合作一點都不複雜，困難的事情JumboFun來做，最後整理成一個簡單好用的介面，讓你可以更聚焦在關鍵點上.</p>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <FaGlasses style={{'font-size': 70}}/>
                            </div>
                            <h3>用你的角度看世界</h3>
                            <p style={{'height': 120}}>JumboFune關心你商業的成長，從你的角度，透視你的商業世界，可以創造出更適合你用的工具!</p>
                        </div>
                    </div>
                    
                   
                </div>
            </div>
        </section>
    )
}

export default OurFeatures