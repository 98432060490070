import React from 'react'
import {Link} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'
import { SiOpenstreetmap } from 'react-icons/Si';
import { GrCloudSoftware } from 'react-icons/Gr';
import { FaRegChartBar } from 'react-icons/Fa';

const OurSolutions = () => {
    return (
        <section className="solutions-area pb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="star" /> 
                        JumboFun's Solutions
                    </span>
                    <h2>鑽探資料，淬鍊出絕佳方案!</h2>
                    <p>從確認關鍵問題，累積相關資料，建立分析模型，到設計行動方案，一次搞定!!</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">         
                                <SiOpenstreetmap/>
                            </div>
                            <h2>
                                
                                 繪製客戶旅程地圖
                                
                            </h2>
                            <p>Customer Journey Drawing and Mapping</p>
                            <p>把你的顧客體驗服務或使用產品的完整流程圖繪製出來</p>
                            <p>吸引顧客的關鍵點，就在裡面!</p>

                            
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <FaRegChartBar/>
                            </div>

                            <h2>
                                
                                實驗及數據分析
                                
                            </h2>

                            <p>Experiment and Data Analysis</p>
                            <p>只是繪製旅程地圖，怎能說服我那是關鍵點?</p>
                            <p>實驗，產生數據，確認關鍵點存在!</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <GrCloudSoftware/>
                            </div>

                            <h2>
                               
                                     雲端科技應用
                                
                            </h2> 

                            <p>Cloud Technology Application</p>
                            <p>利用網路科技，把解決方案自動化，24小時隨時服務</p>
                            <p>隨時解決顧客問題，是商業成長的關鍵!</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurSolutions;