import React from 'react'
import service1 from '../../assets/images/services/service1.png'
import service2 from '../../assets/images/services/service2.png'
import starIcon from '../../assets/images/star-icon.png'

const OurServices = () => {
    return (
        <React.Fragment>
            {/* Service Left Image Style */}
            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img src={service1} alt="service" />
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content">
                                    <span className="sub-title">
                                        <img src={starIcon} alt="icon" /> JumboFun's Service
                                    </span>
                                    
                                    <h2>網路應用程式設計</h2>
                                    <p>利用最新的雲端網路科技，為你創造24小時，不間斷服務的絕佳員工</p>
                                    <ul className="about-list mb-0">
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            行銷網站架設
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            API應用設計
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            雲端資料庫設計
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            資料交換網站設計
                                        </li>
                                        
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Service Left Image Style */}

            {/* Service Right Image Style */}
            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content">
                                    <span className="sub-title">
                                        <img src={starIcon} alt="icon" /> JumboFun's Service
                                    </span>
                                    
                                    <h2>數據處理、分析及應用設計</h2>
                                    <p>你覺得資料庫有很多資料，但是不知道怎麼用?讓JumboFun來幫你!</p>

                                    <ul className="our-mission-list mb-0">
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            顧客資料視覺化分析
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            產品資料視覺化分析
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            資料處理流程優化程式
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            AI模型科技應用
                                        </li>
                                        
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img src={service2} alt="service" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Service Right Image Style */}
        </React.Fragment>
    )
}

export default OurServices;