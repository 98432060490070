import React from 'react'
import {Link} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'

const Pricing = () => {
    return (
        <div className="membership-levels-area ptb-100">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="priceing" /> 
                        JumboFun's Pricing
                    </span>
                    <h2>收費標準</h2>
                    <p>僅供參考，實際費用依洽談需求後，完整報價</p>
                </div>

                <div className="membership-levels-table table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>
                                    <span className="price">服務項目</span>
                                </th>
                                <th>
                                    <span className="price">特色</span>
                                    {/* <span className="title">Basic Plan</span>
                                    <span className="desc">/Monthly</span> */}
                                </th>
                                 <th>
                                    <span className="price">收費標準</span>
                                    {/* <span className="title">Advanced Plan</span>
                                    <span className="desc">/Monthly</span> */}
                                </th>
                                {/*<th>
                                    <span className="price">$65.00</span>
                                    <span className="title">Expert Plan</span>
                                    <span className="desc">/Monthly</span>
                                </th> */}
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td style={{'text-align': 'center','font-size':20}}>資料處理小程序</td>
                                <td>你有大量的Excel資料要轉來轉去?耗費大量人工，JumboFun幫你</td>
                                <td>NT 5,000 起</td>
                            </tr>
                            <tr>
                                <td style={{'text-align': 'center','font-size':20}}>行銷網站架設</td>
                                <td>網站已經成為公司或是個人的基本名片，你還沒有?Jumbofun幫你</td>
                                <td>NT 5,000 起</td>
                                
                            </tr>
                            
                            <tr>
                                <td style={{'text-align': 'center','font-size':20}}>爬蟲程式設計</td>
                                <td>你想要知道蝦皮競爭對手的價格變化，不想再點網頁?JumboFun幫你</td>
                                <td>NT 5,000 起</td>
                                
                            </tr>
                            <tr>
                                <td style={{'text-align': 'center','font-size':20}}>數據分析專案</td>
                                <td>你有數據，想要找出脈絡? JumboFun幫你</td>
                                <td>NT 10,000 起</td>     
                            </tr>

                            
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Pricing